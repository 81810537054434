import { Icon } from "@chakra-ui/react";
import React from "react";

export function BallPenFill(props) {
  return (
    <Icon viewBox="0 0 24 24" width="24px" height="24px" {...props}>
      <path
        d="M17.849 11.808l-.707-.707-9.9 9.9H3v-4.243L14.313 5.444l5.657 5.657a1 1 0 0 1 0 1.414l-7.07 7.071-1.415-1.414 6.364-6.364zm.707-9.192l2.829 2.828a1 1 0 0 1 0 1.414L19.97 8.273 15.728 4.03l1.414-1.414a1 1 0 0 1 1.414 0z"
        fill="currentColor"
      />
    </Icon>
  );
}
