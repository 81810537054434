import { Icon } from "@chakra-ui/react";
import React from "react";

export function ArrowDownLine(props) {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 13.172L16.95 8.22198L18.364 9.63598L12 16L5.636 9.63598L7.05 8.22198L12 13.172Z"
        fill="currentColor"
      />
    </Icon>
  );
}
